#logo {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 97px;
  height: 88px; */
  overflow: hidden;
}
#logo img {
  width: 100%;
}
#logo:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100px;
  width: 70px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(30deg);
  animation-name: slide;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}
@keyframes slide {
  0% {
    left: -100;
    top: 0;
  }
  /* 50% {
    left: 50px;
    top: 0px;
  } */
  100% {
    left: 100px;
    top: 0;
  }
}
